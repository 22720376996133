<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      ref="sidebar-create"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar escola' : 'Editar escola'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-tabs content-class="mt-2" align="center">
            <b-tab active>
              <template #title>
                <span>{{dataSchool.id == null ? 'Cadastrar' : 'Editar'}}</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRules">
                  <b-form-input class="hidden" name="_token" :value="csrf" />
                  <b-form-group 
                    label="* Empresa:" 
                    label-for="i-company"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="empresa"
                      rules="required"
                    >
                      <b-form-select
                        id="i-company"
                        v-model="dataSchool.company_id"
                        :options="companies"
                        value-field="id"
                        text-field="company_name"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        @change="showCompany"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-overlay :show="isLoadingInep" opacity="0.70" rounded="sm" variant="transparent">
                  <b-form-group
                    label="INEP:"
                    label-for="i-inep"
                    class="pl-1 pr-1"
                  >
                    <b-form-input
                      id="i-inep"
                      placeholder="Ex: 12345678"
                      v-model="dataSchool.inep"
                      @change="fetchSchoolsInep"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Nome da escola:"
                    label-for="i-school-1"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nome da escola"
                      rules="required"
                    >
                      <b-form-input
                        id="i-school-1"
                        placeholder="Ex: Nome da escola"
                        v-model="dataSchool.name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="IDEB A.I.:"
                    label-for="i-idebAI "
                    class="pl-1 pr-1"
                  >
                    <b-form-input
                      id="i-idebAI "
                      type="number"
                      placeholder="Ex: IDEB A.I."
                      v-model="dataSchool.ideb_ai"
                    />
                  </b-form-group>
                  <b-form-group
                    label="IDEB A.F.:"
                    label-for="i-idebAf"
                    class="pl-1 pr-1"
                  >
                    <b-form-input
                      id="i-idebAf"
                      type="number"
                      placeholder="Ex: IDEB A.f."
                      v-model="dataSchool.ideb_af"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Rua:"
                    label-for="i-street"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="rua"
                      rules="required"
                    >
                      <b-form-input
                        id="i-street"
                        v-model="dataSchool.address.street"
                        placeholder="Ex: Rua da colina, n° 123"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Cidade:"
                    label-for="i-city"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cidade"
                      rules="required"
                    >
                      <b-form-input
                        id="i-city"
                        v-model="dataSchool.address.city"
                        placeholder="Ex: Cascavel"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Estado:"
                    label-for="i-state"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cidade"
                      rules="required"
                    >
                      <b-form-select
                        v-model="dataSchool.address.state"
                        :options="states"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Tipo de Material:"
                    label-for="i-state"
                    class="pl-1 pr-1"
                  >
                    <b-form-select
                      v-model="dataSchool.material_type"
                      :options="type_materials"
                      value-field="value"
                      text-field="name"
                    />
                  </b-form-group>
<!--              <div v-if="showCompanyDetails">
                    <div class="divider my-2">
                      <div class="divider-text">
                        Licenças
                      </div>
                    </div>
                    <p class="text-center">A empresa <b>{{showCompanyDetails.company_name}}</b> tem <b>{{showCompanyDetails.remaining_licences}}</b> licenças disponíveis de <b>{{showCompanyDetails.number_of_licences}}</b>.</p>
                    <b-form-group
                      label='Selecione a quantidade de licenças.'
                      label-for="i-sb-maxmin"
                      class="pl-1 pr-1"
                    >
                      <b-form-spinbutton
                        id="sb-maxmin"
                        v-model="dataSchool.number_of_licences"
                        wrap
                        min="0"
                        :max="showCompanyDetails.remaining_licences"
                      />
                    </b-form-group>
                  </div> -->
                  <div class="divider my-2" v-if="Object.entries(this.dataProp).length === 0">
                    <div class="divider-text">
                      Gestor
                    </div>
                  </div>
                  <b-form-group
                    v-if="Object.entries(this.dataProp).length === 0"
                    label="Nome:"
                    label-for="i-name-user"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules="required"
                    >
                      <b-form-input
                        id="name-user"
                        v-model="dataSchool.user.name"
                        placeholder="Nome"
                        name="i-name-user"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="Object.entries(this.dataProp).length === 0"
                    label="Email:"
                    label-for="i-email-company"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="dataSchool.user.email"
                        placeholder="Ex: emailempresa@gmail.com"
                        name="i-email-company"
                        class="form-control"
                        type="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="Object.entries(this.dataProp).length === 0"
                    label="CPF (Usuário):"
                    label-for="i-cpf-company"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CPF"
                      rules="required"
                    >
                      <b-form-input
                        id="cpf"
                        v-mask="'###.###.###-##'"
                        v-model="dataSchool.user.cpf"
                        placeholder="123.456.789-01"
                        name="i-cpf-compnay"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="Object.entries(this.dataProp).length === 0"
                    label="Senha:"
                    label-for="i-password"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="senha"
                      rules="required|password"
                    >
                      <b-form-input
                        id="password"
                        v-model="dataSchool.user.password"
                        placeholder="**************"
                        name="i-password"
                        class="form-control"
                        type="password"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  </b-overlay>
                  
                  <b-row style="margin-right: 0rem !important;" class="mb-1">
                    <b-col lg="3" sm="6" class="mr-1 ml-1">
                      <b-button
                        variant="relief-primary"
                        @click="storeContract((value = 1))"
                        :disabled="dataSchool.name == null"
                      >
                        {{this.dataSchool.id == null ? 'Salvar' : 'Editar'}}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Fechar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                </b-form>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Importar</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRulesImport">
                    <b-form-input class="hidden" name="_token" :value="csrf" />
                    <b-form-group 
                      label="* Empresa:" 
                      label-for="i-school-2"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="escola"
                        rules="required"
                      >
                        <b-form-select
                          id="i-school-2"
                          v-model="dataImport.company_id"
                          :options="companies"
                          value-field="id"
                          text-field="company_name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Arquivo:"
                      label-for="i-file2"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="arquivo"
                        rules="required"
                      >
                      <b-form-file
                        placeholder="Arquivo"
                        v-model="dataImport.file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="file"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-row style="margin-right: 0rem !important;">
                      <b-col lg="5" sm="6" class="mr-1 ml-1">
                        <b-button
                          variant="relief-primary"
                          @click="importDirector()"
                          :disabled="dataImport.company_id == null"
                          block
                        >
                          Importar
                        </b-button>
                      </b-col>
                      <b-col>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          @click="closeSidebar()"
                          block
                        >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>

                  <b-link class="mt-2 d-block text-center" href="/escola_e_diretores.xlsx" target="_blank">
                    <feather-icon icon="DownloadIcon" class="mr-1" />Baixar arquivo de exemplo
                  </b-link>
                </b-form>
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import statesCities from './dataStatesCities'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {

      showCompanyDetails: null,

      type_materials: [
        {value: "fisico", name: "Material físico"},
        {value: "digital", name: "Material digital"},
        {value: "hibrido", name: "Material híbrido"},
      ],

      icon: null,
      dataImport: {
        company_id: null,
        file: null,
      },
      dataSchool: {
        company_id: null,
        inep: null,
        name: null,
        ideb_ai: null,
        ideb_af: null,
        number_of_licences: 0,
        material_type: null,
        address: {
          street: null,
          city: null,
          state: null,
        },
        user: {
          name: null,
          username: null,
          cpf: null,
          email: null,
          password: null
        }
      },
      companies: [],
      categoryOptions: [
        { value: 'publica', text: 'Pública' },
        { value: 'privada', text: 'Privada' }
      ],
      isLoadingInep: false,
      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    // ...mapState("companies", ["companies"]),
    ...mapState("schools", ["schools", "schoolsInep"]),
    
  },
  methods: {
    ...mapActions("schools", ["store", "update"]),
    ...mapActions("directors", ["import"]),
    storeContract(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (Object.entries(this.dataProp).length === 0) { 
            this.dataSchool.user.username = this.dataSchool.user.cpf;
            this.store(this.dataSchool).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })

              this.dataSchool.id = null;
              this.dataSchool.company_id = null;
              this.dataSchool.fantasy_name = null;
              this.dataSchool.cnpj = null;
              this.dataSchool.category = 'publica';
              this.dataSchool.phone = null;
              this.dataSchool.classroom_domain = null;
              this.dataSchool.status = null;
              this.dataSchool.number_of_licences = 0;
              this.dataSchool.material_type = null;
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .then(({ data }) => {
              if(value == 1){
                this.closeSidebar()
              }
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          } else {
            this.dataSchool.user.username = this.dataSchool.user.cpf;
            this.update(this.dataSchool).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })

              this.dataSchool.id = null;
              this.dataSchool.company_id = null;
              this.dataSchool.fantasy_name = null;
              this.dataSchool.cnpj = null;
              this.dataSchool.category = 'publica';
              this.dataSchool.phone = null;
              this.dataSchool.classroom_domain = null;
              this.dataSchool.status = null;
              this.dataSchool.material_type = null;
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .then(({ data }) => {
              this.closeSidebar()
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
    importDirector(){
      this.$refs.simpleRulesImport.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.import(this.dataImport).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Importado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              }
            })
            this.dataImport.company_id = null;
            this.dataImport.file = null;
            this.$nextTick(() => this.$refs.simpleRulesImport.reset());
          }).finally(() => {
            this.fetchSchools(); 
            this.isLoadingStore = false
            this.closeSidebar()
          })
        }
      });
    },
    fetchCompanies(paginated = false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("companies/fetchCompanies", {
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.companies = response
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchSchools(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("schools/fetchSchools", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchSchoolsInep(value){
      this.isLoadingInep = true
      let data = value
      this.$store
        .dispatch("schools/fetchSchoolsInep", data)
        .then((data) => {
          this.dataSchool.name = data.nome
          this.dataSchool.ideb_ai = data.idebAI
          this.dataSchool.ideb_af = data.idebAF
          if(data.endereco == "*** Endereço não localizado. Mostrando localização aproximada ***"){
            this.dataSchool.address.street = null
          }else{
            this.dataSchool.address.street = data.endereco
          }
          this.dataSchool.address.city = data.nomeMunicipio
          this.dataSchool.address.state = data.siglaUf
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Escola encontrada!',
              icon: 'SearchIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
        })
        .catch((err) => {
          console.error(err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Escola não encontrada!',
              icon: 'SearchIcon',
              text: 'Não encontramos a escola referente ao INEP.',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.isLoadingInep = false
        })
    },
    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    },
    showCompany(id){
      this.$store.dispatch("companies/fetchDetails",{
        company_id: id
      })
      .then((response) => {
        this.showCompanyDetails =  response
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
      })
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      if (Object.entries(this.dataProp).length === 0) { 
        //this.initValues(); 
        //this.$validator.reset(); 
      } else { 
        let { id, inep, name, address, company_id } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataSchool.id = id;
        this.dataSchool.company_id = company_id;
        this.dataSchool.inep = inep;
        this.dataSchool.name = name;
        this.dataSchool.address = address ? address : {};
        //this.initValues(); 
      } 
    }
  }
  },
  computed: {
    // a computed getter
    states: function () {
      let states2 = [];
      statesCities.map(estado => {
        let item = {
          value: estado.uf,
          text: estado.name
        }
        states2.push(item);
      });
      return states2
    }
  },
  created() {
    this.fetchCompanies();
  },
}
</script>