<template>
  <b-card>
    <b-row v-show="schools.length > 0 || search != null">
      <b-col md="7">
        <b-row>
          <b-col md="4">
            <b-form-group label="Exibir" label-for="h-exibir" label-cols-md="4" class="text-muted">
              <b-form-select size="sm" class="form-content" v-model="perPage" :options="perPageOptions"
                :disabled="isLoading" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Pesquisar" v-model="search" debounce="800" type="search" size="sm"
            :disabled="isLoading" />
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-button v-if="$can('read', 'COMPANY_ADMIN')" block variant="relief-primary" @click="addNew"
          v-b-toggle.sidebar-create size="sm">
          Nova Escola
          <feather-icon icon="GridIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div v-show="schools.length > 0 || search != null" style="display: block;">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <b-table-simple striped responsive class="mt-2" style="display: inline" id="tableSchools">
          <b-thead>
            <b-tr class="text-center">
              <b-th>INEP</b-th>
              <b-th>Nome escola</b-th>
              <b-th>IDEB A.I.</b-th>
              <b-th>IDEB A.F.</b-th>
              <b-th>Endereço</b-th>
              <b-th>Cidade</b-th>
              <b-th>Estado</b-th>
              <b-th>Total de licenças</b-th>
              <b-th>Material</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(tr, indextr) in schools" :key="indextr">
              <b-td>
                {{ tr.inep }}
              </b-td>
              <b-td>
                <a :href="'/escola/' + tr.id"
                  @click="saveId(tr.id)" 
                >
                  {{ tr.name }}
                </a>
              </b-td>
              <b-td class="text-center">
                <p
                  style="width: 4ch; display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ tr.last_ideb ? tr.last_ideb.ideb_ai : '' }}
                </p>
              </b-td>
              <b-td class="text-center">
                <p
                  style="width: 4ch; display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ tr.last_ideb ? tr.last_ideb.ideb_af : '' }}
                </p>
              </b-td>
              <b-td class="text-center">
                {{ tr.address ? tr.address.street : '' }}
              </b-td>
              <b-td class="text-center">
                {{ tr.address ? tr.address.city : '' }}
              </b-td>
              <b-td class="text-center">
                {{ tr.address ? tr.address.state : '' }}
              </b-td>
              <b-td class="text-center">
                {{ tr.number_of_licences }}
              </b-td>
              <b-td class="text-center">
                <b-badge v-if="tr.material_type == 'fisico'" variant="success" class="badge-glow">
                  Físico
                </b-badge>
                <b-badge v-if="tr.material_type == 'digital'" variant="info" class="badge-glow">
                  Digital
                </b-badge>
                <b-badge v-if="tr.material_type == 'hibrido'" variant="warning" class="badge-glow">
                  Híbrido
                </b-badge>
              </b-td>
              <b-td class="text-center">
                <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary"
                  class="dropdown-icon-wrapper" right>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
                  </template>
                  <b-dropdown-item v-if="$can('read', 'SCHOOL_ADMIN')" :href="'/escola/' + tr.id" @click="saveId(tr.id)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span class="align-middle">Detalhe</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('read', 'ADMIN')" @click="showSchool(tr.id, value = 2)"
                    v-b-modal.modal-license>
                    <feather-icon icon="CalendarIcon" class="mr-50" />
                    <span class="align-middle">Licenças</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('read', 'ADMIN')" v-b-toggle.sidebar-create @click="updateDetail(tr.id)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span class="align-middle">Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('read', 'ADMIN')" v-b-modal.modal-delete @click="showDetail(tr.id)">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span class="align-middle">Excluir</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('read', 'ADMIN')" v-b-modal.modal-update-ideb
                    @click="showSchool(tr.id, value = 1)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Editar IDEB</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div class="row" v-show="totalRows > 10">
          <div class="col-6 col-md-9">
            Exibindo {{ perPage }} de {{ totalRows }} registros
          </div>
          <div class="col-6 col-md">
            <b-pagination v-model="currentPage" class="text-center float-right" :per-page="perPage"
              :total-rows="totalRows" aria-controls="tableSchools" size="sm" @change="handlePageChange" />
          </div>
        </div>

      </b-overlay>
    </div>
    <div class="text-center" v-show="schools.length == 0 && search == null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <no-data />
        <b-button variant="relief-primary" @click="addNew" v-b-toggle.sidebar-create size="sm">
          Nova escola
          <feather-icon icon="GridIcon" />
        </b-button>
      </b-overlay>
    </div>
    <create-edit v-if="$can('read', 'COMPANY_ADMIN')" :key="count" :dataProp="sidebarData" />

    <!-- Modal Delete -->
    <b-modal id="modal-delete" ok-variant="danger" modal-class="modal-danger" ref="modal-delete" centered title="Deletar"
      v-if="schoolModel != null">
      <b-card-text>
        <p>Você está preste a deletar esta escola do sistema: <b>{{ schoolModel.name }}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox id="checkbox-1" @change="newDeleteStatus" v-model="DeleteStatus" name="checkbox-1"
          value="accepted">
          Concordo em excluir esta escola
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-danger" block
            :disabled="DeleteStatus == false" @click="deleteSchool(schoolModel.id)">
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>

    <!-- Modal license -->
    <b-modal id="modal-license" ok-variant="primary" modal-class="modal-primary" ref="modal-license" centered
      title="Gerenciar licenças">
      <b-overlay :show="isLoadingLicence" opacity="0.70" variant="transparent" rounded="sm">
        <b-card-text v-if="showSchoolDetails">
          <p>A empresa <b>{{ showSchoolDetails.company.company_name }}</b> tem
            <b>{{ showSchoolDetails.company.remaining_licences }}</b> licenças disponíveis de <b>
              {{ showSchoolDetails.company.number_of_licences }} </b>.</p>
          <b-form-group label='Selecione a quantidade de licenças.' label-for="i-sb-maxmin" class="pl-1 pr-1">
            <b-form-spinbutton id="sb-maxmin" v-model="license.number_of_licences" wrap min="1"
              :max="showSchoolDetails.company.remaining_licences" />
          </b-form-group>
        </b-card-text>
      </b-overlay>
      <template #modal-footer>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-primary" block
            @click="provideLicense(license.school_id = showSchoolDetails.id)">
            Disponibilizar licenças
          </b-button>
        </b-col>
      </template>
    </b-modal>

    <!-- Update IDEB -->
    <b-modal id="modal-update-ideb" ok-variant="primary" modal-class="modal-primary" ref="modal-update-ideb" centered
      title="Editar ou Atualizar IDEB">
      <b-overlay :show="isLoadingIdeb" opacity="0.70" variant="transparent" rounded="sm">
        <b-card-text v-if="showSchoolIdebDetails">
          <p>Escola: <b>{{ showSchoolDetails.name }}</b>.</p>
          <b-form-group label='Ano' label-for="i-sb-maxmin" class="pl-1 pr-1">
            <b-form-input type="number" placeholder="2021" v-model="updateDataIdeb.year" />
          </b-form-group>
          <b-form-group label='IDEB A.I.' label-for="i-sb-maxmin" class="pl-1 pr-1">
            <b-form-spinbutton id="sb-maxmin" v-model="updateDataIdeb.ideb_ai" wrap step="0.1" min="0" max="10" />
          </b-form-group>
          <b-form-group label='IDEB A.F.' label-for="i-sb-maxmin" class="pl-1 pr-1">
            <b-form-spinbutton id="sb-maxmin" v-model="updateDataIdeb.ideb_af" wrap step="0.1" min="0" max="10" />
          </b-form-group>
        </b-card-text>
      </b-overlay>
      <template #modal-footer>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-primary" block
            @click="updateIdebs">
            Editar ou Atualizar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEdit from "./CreateEdit.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    CreateEdit,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingIdeb: true,
      isLoadingLicence: true,

      showIcon: false,

      license: {
        number_of_licences: null,
        school_id: null
      },


      DeleteStatus: false,
      schoolModel: null,
      showSchoolDetails: null,
      showSchoolIdebDetails: null,

      updateDataIdeb: {
        school_id: null,
        year: null,
        ideb_ai: null,
        ideb_af: null
      },

      sidebarData: {
        id: null,
        name: null,
        number_of_licences: 0,
        administrative_dependency: null,
        address: {}
      },
      sidebarActive: false,

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      //csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("schools", ["schools", "schoolShow"])
  },
  methods: {
    ...mapActions("schools", ["fetchDetails", "setLicense", "updateIdeb"]),

    addNew() {
      this.sidebarData = {};
      this.count++;
    },

    saveId(id) {
      localStorage.removeItem('school_id')
      localStorage.setItem('school_id', id)
    },

    fetchSchools(paginated = true, page = 1) {
      this.isLoading = true
      this.$store
        .dispatch("schools/fetchSchools", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          if (JSON.parse(localStorage.getItem('userData')).type == 'SCHOOL_ADMIN') {
            if (response.data.length == 1) {
              this.$router.push("/escola/" + response.data[0].id)
            }
          }
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchSchools(true, value)
    },

    showSchool(id, value) {
      if (value == 1) {
        this.isLoadingIdeb = true
      } else if (value == 2) {
        this.isLoadingLicence = true
      }
      this.$store
        .dispatch("schools/fetchDetails", {

          school_id: id
        })
        .then((response) => {
          this.showSchoolDetails = response
          if (value == 1) {
            this.showSchoolIdebDetails = response
            this.updateDataIdeb.school_id = id
            this.updateDataIdeb.year = response.last_ideb.year
            this.updateDataIdeb.ideb_ai = response.last_ideb.ideb_ai
            this.updateDataIdeb.ideb_af = response.last_ideb.ideb_af
          }
          this.license.number_of_licences = response.number_of_licences
        })
        .finally(() => {
          if (value == 1) {
            this.isLoadingIdeb = false
          } else if (value == 2) {
            this.isLoadingLicence = false
          }
        })
    },

    updateIdebs() {
      this.updateIdeb(this.updateDataIdeb)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Editado e atualizado com sucesso',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
        })
        .finally(() => {
          this.fetchSchools()
          this.$refs['modal-update-ideb'].hide()
        })
    },

    provideLicense() {
      this.setLicense(this.license)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Licença liberada com sucesso',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
        })
        .finally(() => {
          this.fetchSchools()
          this.$refs['modal-license'].hide()
        })
    },

    showDetail(id) {
      this.schoolModel = this.$store.getters['schools/getSchoolById'](id)
    },

    newDeleteStatus(value) {
      this.DeleteStatus = value
    },

    deleteSchool(id) {
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("schools/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.fetchSchools()
          this.$refs['modal-delete'].hide()
        })
    },

    updateDetail(id) {
      this.count++;
      this.updateModel = this.$store.getters['schools/getSchoolById'](id);
      this.sidebarData.id = this.updateModel.id;
      this.sidebarData.name = this.updateModel.name;
      this.sidebarData.inep = this.updateModel.inep;
      this.sidebarData.number_of_licences = this.updateModel.number_of_licences;
      this.sidebarData.administrative_dependency = this.updateModel.administrative_dependency;
      this.sidebarData.material_type = this.updateModel.material_type;
      this.sidebarData.address = this.updateModel.address;
    },
  },
  created() {
    this.fetchSchools();
  },
  watch: {
    perPage() {
      this.fetchSchools()
    },
    search() {
      this.currentPage = 1;
      this.fetchSchools(true, 1);
    }
  },
};
</script>
<style lang="scss">
.form-content {
  width: 5rem;

}

.form-content-search {
  width: 11rem;
}
</style>