var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sidebar-create",attrs:{"id":"sidebar-create","no-close-on-backdrop":"","no-close-on-esc":"","shadow":"","right":"","bg-variant":"white","backdrop":"","title":Object.entries(this.dataProp).length === 0 ? 'Cadastrar escola' : 'Editar escola'}},[_c('div',[_c('hr'),_c('b-overlay',{attrs:{"show":_vm.isLoadingStore,"opacity":"0.70","rounded":"sm"}},[_c('b-tabs',{attrs:{"content-class":"mt-2","align":"center"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.dataSchool.id == null ? 'Cadastrar' : 'Editar'))])]},proxy:true}])},[_c('div',[_c('b-form',{attrs:{"method":"POST"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form-input',{staticClass:"hidden",attrs:{"name":"_token","value":_vm.csrf}}),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"* Empresa:","label-for":"i-company"}},[_c('validation-provider',{attrs:{"name":"empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"id":"i-company","options":_vm.companies,"value-field":"id","text-field":"company_name","state":errors.length > 0 ? false:null},on:{"change":_vm.showCompany},model:{value:(_vm.dataSchool.company_id),callback:function ($$v) {_vm.$set(_vm.dataSchool, "company_id", $$v)},expression:"dataSchool.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-overlay',{attrs:{"show":_vm.isLoadingInep,"opacity":"0.70","rounded":"sm","variant":"transparent"}},[_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"INEP:","label-for":"i-inep"}},[_c('b-form-input',{attrs:{"id":"i-inep","placeholder":"Ex: 12345678"},on:{"change":_vm.fetchSchoolsInep},model:{value:(_vm.dataSchool.inep),callback:function ($$v) {_vm.$set(_vm.dataSchool, "inep", $$v)},expression:"dataSchool.inep"}})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Nome da escola:","label-for":"i-school-1"}},[_c('validation-provider',{attrs:{"name":"nome da escola","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-school-1","placeholder":"Ex: Nome da escola","state":errors.length > 0 ? false:null},model:{value:(_vm.dataSchool.name),callback:function ($$v) {_vm.$set(_vm.dataSchool, "name", $$v)},expression:"dataSchool.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"IDEB A.I.:","label-for":"i-idebAI "}},[_c('b-form-input',{attrs:{"id":"i-idebAI ","type":"number","placeholder":"Ex: IDEB A.I."},model:{value:(_vm.dataSchool.ideb_ai),callback:function ($$v) {_vm.$set(_vm.dataSchool, "ideb_ai", $$v)},expression:"dataSchool.ideb_ai"}})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"IDEB A.F.:","label-for":"i-idebAf"}},[_c('b-form-input',{attrs:{"id":"i-idebAf","type":"number","placeholder":"Ex: IDEB A.f."},model:{value:(_vm.dataSchool.ideb_af),callback:function ($$v) {_vm.$set(_vm.dataSchool, "ideb_af", $$v)},expression:"dataSchool.ideb_af"}})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Rua:","label-for":"i-street"}},[_c('validation-provider',{attrs:{"name":"rua","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-street","placeholder":"Ex: Rua da colina, n° 123","state":errors.length > 0 ? false:null},model:{value:(_vm.dataSchool.address.street),callback:function ($$v) {_vm.$set(_vm.dataSchool.address, "street", $$v)},expression:"dataSchool.address.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Cidade:","label-for":"i-city"}},[_c('validation-provider',{attrs:{"name":"cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-city","placeholder":"Ex: Cascavel","state":errors.length > 0 ? false:null},model:{value:(_vm.dataSchool.address.city),callback:function ($$v) {_vm.$set(_vm.dataSchool.address, "city", $$v)},expression:"dataSchool.address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Estado:","label-for":"i-state"}},[_c('validation-provider',{attrs:{"name":"cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.states,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataSchool.address.state),callback:function ($$v) {_vm.$set(_vm.dataSchool.address, "state", $$v)},expression:"dataSchool.address.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Tipo de Material:","label-for":"i-state"}},[_c('b-form-select',{attrs:{"options":_vm.type_materials,"value-field":"value","text-field":"name"},model:{value:(_vm.dataSchool.material_type),callback:function ($$v) {_vm.$set(_vm.dataSchool, "material_type", $$v)},expression:"dataSchool.material_type"}})],1),(Object.entries(this.dataProp).length === 0)?_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Gestor ")])]):_vm._e(),(Object.entries(this.dataProp).length === 0)?_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Nome:","label-for":"i-name-user"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"name-user","placeholder":"Nome","name":"i-name-user"},model:{value:(_vm.dataSchool.user.name),callback:function ($$v) {_vm.$set(_vm.dataSchool.user, "name", $$v)},expression:"dataSchool.user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3453182575)})],1):_vm._e(),(Object.entries(this.dataProp).length === 0)?_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Email:","label-for":"i-email-company"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"email","placeholder":"Ex: emailempresa@gmail.com","name":"i-email-company","type":"email"},model:{value:(_vm.dataSchool.user.email),callback:function ($$v) {_vm.$set(_vm.dataSchool.user, "email", $$v)},expression:"dataSchool.user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3369098642)})],1):_vm._e(),(Object.entries(this.dataProp).length === 0)?_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"CPF (Usuário):","label-for":"i-cpf-company"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",attrs:{"id":"cpf","placeholder":"123.456.789-01","name":"i-cpf-compnay"},model:{value:(_vm.dataSchool.user.cpf),callback:function ($$v) {_vm.$set(_vm.dataSchool.user, "cpf", $$v)},expression:"dataSchool.user.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2261467770)})],1):_vm._e(),(Object.entries(this.dataProp).length === 0)?_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Senha:","label-for":"i-password"}},[_c('validation-provider',{attrs:{"name":"senha","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"password","placeholder":"**************","name":"i-password","type":"password","autocomplete":"off"},model:{value:(_vm.dataSchool.user.password),callback:function ($$v) {_vm.$set(_vm.dataSchool.user, "password", $$v)},expression:"dataSchool.user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1757722864)})],1):_vm._e()],1),_c('b-row',{staticClass:"mb-1",staticStyle:{"margin-right":"0rem !important"}},[_c('b-col',{staticClass:"mr-1 ml-1",attrs:{"lg":"3","sm":"6"}},[_c('b-button',{attrs:{"variant":"relief-primary","disabled":_vm.dataSchool.name == null},on:{"click":function($event){_vm.storeContract((_vm.value = 1))}}},[_vm._v(" "+_vm._s(this.dataSchool.id == null ? 'Salvar' : 'Editar')+" ")])],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","block":""},on:{"click":function($event){return _vm.closeSidebar()}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Importar")])]},proxy:true}])},[_c('div',[_c('b-form',{attrs:{"method":"POST"}},[_c('validation-observer',{ref:"simpleRulesImport"},[_c('b-form-input',{staticClass:"hidden",attrs:{"name":"_token","value":_vm.csrf}}),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"* Empresa:","label-for":"i-school-2"}},[_c('validation-provider',{attrs:{"name":"escola","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"id":"i-school-2","options":_vm.companies,"value-field":"id","text-field":"company_name","state":errors.length > 0 ? false:null},model:{value:(_vm.dataImport.company_id),callback:function ($$v) {_vm.$set(_vm.dataImport, "company_id", $$v)},expression:"dataImport.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pl-1 pr-1",attrs:{"label":"Arquivo:","label-for":"i-file2"}},[_c('validation-provider',{attrs:{"name":"arquivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Arquivo","type":"file","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","name":"file","state":errors.length > 0 ? false:null},model:{value:(_vm.dataImport.file),callback:function ($$v) {_vm.$set(_vm.dataImport, "file", $$v)},expression:"dataImport.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticStyle:{"margin-right":"0rem !important"}},[_c('b-col',{staticClass:"mr-1 ml-1",attrs:{"lg":"5","sm":"6"}},[_c('b-button',{attrs:{"variant":"relief-primary","disabled":_vm.dataImport.company_id == null,"block":""},on:{"click":function($event){return _vm.importDirector()}}},[_vm._v(" Importar ")])],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","block":""},on:{"click":function($event){return _vm.closeSidebar()}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('b-link',{staticClass:"mt-2 d-block text-center",attrs:{"href":"/escola_e_diretores.xlsx","target":"_blank"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"DownloadIcon"}}),_vm._v("Baixar arquivo de exemplo ")],1)],1)],1)])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }